@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .h-screen {
    height: 100dvh;
  }
}

header.scrolled {
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.7);
}

.custom-height-80 {
  height: 80vw;
  max-height: 80%;
}

.custom-height-60 {
  height: 60vw;
  max-height: 60%;
}